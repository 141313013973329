header {
    padding: 8px 24px !important;
    box-shadow: var(--color-shadow-s);
    background-color: var(--color-theme-p) !important;

    z-index: 1000 !important;

    #headerBanner {

        a {
            color: var(--color-text-p-dm) !important;
            font-size: 24px;
        }

    }

    .menu-horizontal li a {
        color: #fff !important;
    }

    .menu-horizontal > li > ul {
        box-shadow: var(--color-shadow-s) !important;
        background-color: var(--color-layout-header) !important;

        z-index: 9999 !important;

        li a {
            color: var(--color-text-p) !important;

            &:hover {
                background-color: var(--color-action-hover) !important;
            }
            
        }

    }

    .menu-compact, .card-compact {
        box-shadow: var(--color-shadow-s) !important;
        background-color: var(--color-layout-header) !important;

        button {

            &:hover {
                background-color: var(--color-action-hover) !important;
            }

            a {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 12px;
                width: 100%;
    
                color: var(--color-text-p) !important;
    
                svg path {
                    fill: var(--color-text-p) !important;
                }
    
            }

        }

        li {

            a {
                color: var(--color-text-p) !important;
            }

        }

    }

    .card-compact {

        span {  

            &:nth-child(1){
                color: var(--color-text-p) !important;
            }

            &:nth-child(2){
                color: var(--color-text-s) !important;
            }

        }

        #versionDetails {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            span {

                &:nth-child(1){
                    color: var(--color-status-success-p) !important;
                }
    
                &:last-of-type{
                    color: var(--color-text-t) !important;
                }

            }

        }

        button {
            background-color: var(--color-theme-p) !important;

            &:hover {
                background-color: var(--color-theme-p-dark) !important;
            }

        }

    }

}
/*
header {
    position: fixed;
    top: 0;
    z-index: 10;

    height: var(--layout-header-height);
    background-color: var(--color-layout-header);
    box-shadow: var(--color-shadow-s);

    #headerContainer {
        display: grid;
        grid-template-columns: auto auto 1fr auto;
        gap: 0 24px;
        height: 100%;
        padding: 8px 24px;

        .headerContent {
            display: flex;
            flex-direction: row;
            align-items: center;

            &.logo {

                img {
                    width: auto;
                    height: 40px;
                }

            }

            &.options {
                gap: 0 8px;
            }

            &.menu, &.options, &.user {

                .headerOption {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    gap: 0 12px;
                    padding: 8px;
                    border-radius: 24px;

                    cursor: pointer;
                    transition: var(--transition-theme);

                    &:hover {
                        background-color: var(--color-action-hover);

                        svg path {
                            fill: var(--color-text-p) !important;
                        }

                        .headerOptionLabel {
                            color: var(--color-text-p) !important;
                        }

                    }

                    svg path {
                        fill: var(--color-text-s) !important;
                    }

                    .headerOptionLabel {
                        color: var(--color-text-s);
                        font-size: 18px;
                        font-weight: bold;

                        transition: var(--transition-theme);
                    }

                }

            }

        }

    }

}
*/

.qas {

    header {
        background-color: var(--color-status-warning-p) !important;

        .card-compact {

            span {
    
                &:nth-child(3){
                    color: var(--color-status-warning-p) !important;
                }
    
            }

            #versionDetails {
    
                span {
    
                    &:nth-child(1){
                        color: var(--color-status-warning-p) !important;
                    }
    
                }
    
            }

        }

    }

}