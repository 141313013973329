#faqBox {
    background-color: var(--color-layout-p) !important;
    border-radius: 8px;

    button {

        &:hover {
            background-color: var(--color-action-hover) !important;
        }

        span {
            color: var(--color-text-s) !important;
        }

    }

    .faqContent {
        color: var(--color-text-t) !important;

        svg {
            display: initial;
            vertical-align: top;
        }

    }

}