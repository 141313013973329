.textGroup {
    display: flex;
    flex-direction: column;

    span {

        &:nth-of-type(1){
            color: var(--color-text-p);
        }

        &:nth-of-type(2){
            color: var(--color-text-t);
        }

    }

}