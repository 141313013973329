#mainArmazemEditar {

    #titleTravas {
        color: var(--color-text-p);

        transition: var(--transition-theme);
    }

    .selectBox {
        color: var(--color-text-p-dm);
        justify-content: center;

        &:hover {
            color: var(--color-text-p);
        }

    }

}