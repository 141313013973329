.selectBox {

    &.cursor-pointer {

        &:hover {
            background-color: var(--color-action-hover) !important;
        }
        
    }

    &.selected {
        background-color: var(--color-status-info-p);
    }

}